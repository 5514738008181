<template>
  <Page title="Zuständigkeiten" color="error">
    <template slot="icons">
      <v-btn icon @click="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-btn>
    </template>

    <v-row no-gutters>
      <v-col>
        <router-view></router-view>
      </v-col>
      <v-col cols="auto">
        <v-navigation-drawer app right v-model="drawer">
          <v-list dense>
            <v-list-item class="mb-4">
              <v-text-field
                hide-details
                single-line
                label="Suche"
                v-model="search"
                append-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-list-item>
            <v-list-item
              v-for="item in itemsFiltered"
              :key="item.id"
              exact
              :to="{ name: 'RoleDetail', params: { id: item.id } }"
            >
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      items: [],
      loading: false,
      drawer: true,
      search: "",
    };
  },
  watch: {
    itemsFiltered() {
      if (this.itemsFiltered.length == 1) {
        const item = this.itemsFiltered[0];
        if (this.$route.params.id != item.id) {
          this.$router.push({ name: "RoleDetail", params: { id: item.id } });
        }
      }
    },
  },
  async created() {
    this.loading = true;
    this.items = (
      await this.apiList({
        resource: "document/indexentry",
        query: "type=1",
      })
    ).sort((a, b) => {
      if (a.type.id == b.type.id) {
        if (a.sortOrder == b.sortOrder) {
          return a.title.toLowerCase() > b.title.toLowerCase()
            ? 1
            : a.title.toLowerCase() < b.title.toLowerCase()
            ? -1
            : 0;
        } else {
          return a.sortOrder > b.sortOrder
            ? 1
            : a.sortOrder < b.sortOrder
            ? -1
            : 0;
        }
      } else {
        return a.type.id > b.type.id ? 1 : a.type.id < b.type.id ? -1 : 0;
      }
    });
    this.loading = false;
  },

  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          !this.search ||
          item.title.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.keywords != null &&
            item.keywords.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
  },
});
</script>
